
export default async ({ store, req, route }) => {

  // Helper.gqlMutate('ActionLog', 'create', {
  //   input : {
  //     app_name : 'client',
  //     screen_name : route.fullPath,
  //     action_name : 'opened',
  //   }
  // });

  const token = store.getters['auth/token']
  const pathExclude = ['doi-mat-khau']

  if (!store.getters['auth/check'] && token && !pathExclude.includes(route.name)) {
    await store.dispatch('auth/fetchUser')
  }
}
