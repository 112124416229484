export const state = () => ({
  selectedEmployees: [],
  departmentOptions: [],
  employeeGroupOptions: []
})

export const getters = {
  selectedEmployees: (state) => state.selectedEmployees,
  departmentOptions: (state) => state.departmentOptions,
  employeeGroupOptions: (state) => state.employeeGroupOptions,
}

export const mutations = {
  SET_SELECTED_EMPLOYEES(state, selectedEmployees) {
    state.selectedEmployees = selectedEmployees
  },
  CLEAR_SELECTED_EMPLOYEES(state) {
    state.selectedEmployees = []
  },
  ADD_EMPLOYEE(state, employee) {
    state.selectedEmployees.push(employee)
  },
  REMOVE_EMPLOYEE(state, employeeId) {
    state.selectedEmployees = state.selectedEmployees.filter(emp => emp.id !== employeeId)
  },
  UPDATE_EMPLOYEE(state, employee) {
    const index = state.selectedEmployees.findIndex(emp => emp.id === employee.id)
    if (index !== -1) {
      state.selectedEmployees[index] = employee
    }
  },
  ADD_EMPLOYEES_BATCH(state, employees) {
    // Lọc ra những nhân viên chưa tồn tại trong state
    const newEmployees = employees.filter(employee => 
      !state.selectedEmployees.some(emp => emp.id === employee.id)
    )
    state.selectedEmployees.push(...newEmployees)
  },
  REMOVE_EMPLOYEES_BATCH(state, employeeIds) {
    state.selectedEmployees = state.selectedEmployees.filter(
      employee => !employeeIds.includes(employee.id)
    )
  },
  SET_DEPARTMENT_OPTIONS(state, payload) {
    state.departmentOptions = payload
  },
  SET_EMPLOYEE_GROUP_OPTIONS(state, payload) {
    state.employeeGroupOptions = payload
  },
}

export const actions = {
  setSelectedEmployees({ commit }, payload) {
    commit('SET_SELECTED_EMPLOYEES', payload)
  },
  clearSelectedEmployees({ commit }) {
    commit('CLEAR_SELECTED_EMPLOYEES')
  },
  addEmployee({ commit, state }, employee) {
    const exists = state.selectedEmployees.some(emp => emp.id === employee.id)
    if (!exists) {
      commit('ADD_EMPLOYEE', employee)
    } else {
      commit('UPDATE_EMPLOYEE', employee)
    }
  },
  removeEmployee({ commit }, employeeId) {
    commit('REMOVE_EMPLOYEE', employeeId)
  },
  addEmployeeBatch({ commit }, employees) {
    commit('ADD_EMPLOYEES_BATCH', employees)
  },
  removeEmployeeBatch({ commit }, employeeIds) {
    commit('REMOVE_EMPLOYEES_BATCH', employeeIds)
  },
  setDepartmentOptions({ commit }, payload) {
    commit('SET_DEPARTMENT_OPTIONS', payload)
  },
  setEmployeeGroupOptions({ commit }, payload) {
    commit('SET_EMPLOYEE_GROUP_OPTIONS', payload)
  },
}
