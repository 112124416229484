export const state = () => ({
  departmentOptions: [],
  employeeGroupOptions: [],
  positionOptions: []
})

export const getters = {
  departmentOptions: (state) => state.departmentOptions,
  employeeGroupOptions: (state) => state.employeeGroupOptions,
  positionOptions: (state) => state.positionOptions,
}

export const mutations = {
  SET_DEPARTMENT_OPTIONS(state, payload) {
    state.departmentOptions = payload
  },
  SET_EMPLOYEE_GROUP_OPTIONS(state, payload) {
    state.employeeGroupOptions = payload
  },
  SET_POSITION_OPTIONS(state, payload) {
    state.positionOptions = payload
  },
}

export const actions = {
  setDepartmentOptions({ commit }, payload) {
    commit('SET_DEPARTMENT_OPTIONS', payload)
  },
  setEmployeeGroupOptions({ commit }, payload) {
    commit('SET_EMPLOYEE_GROUP_OPTIONS', payload)
  },
  setPositionOptions({ commit }, payload) {
    commit('SET_POSITION_OPTIONS', payload)
  },
}
