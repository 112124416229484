export const state = () => ({
  selectedEmployees: [],
  worktimeRegisterCategoriesMap: {},
  worktimeRegisterCategoryOptions: [],
  departmentOptions: [],
  employeeGroupOptions: [],
  departmentOptions: []
})

export const getters = {
  selectedEmployees: (state) => state.selectedEmployees,
  worktimeRegisterCategoriesMap: (state) => state.worktimeRegisterCategoriesMap,
  worktimeRegisterCategoryOptions: (state) => state.worktimeRegisterCategoryOptions,
  departmentOptions: (state) => state.departmentOptions,
  employeeGroupOptions: (state) => state.employeeGroupOptions,
  positionOptions: (state) => state.positionOptions,
  applicationCategories: (state) => state.applicationCategories,
}

export const mutations = {
  SET_SELECTED_EMPLOYEES(state, selectedEmployees) {
    state.selectedEmployees = selectedEmployees
  },
  CLEAR_SELECTED_EMPLOYEES(state) {
    state.selectedEmployees = []
  },
  SET_SELECTED_WORK_TIME_REGISTER_CATEGORIES_MAP(state, payload) {
    state.worktimeRegisterCategoriesMap = payload
  },
  CLEAR_SELECTED_WORK_TIME_REGISTER_CATEGORIES_MAP(state) {
    state.worktimeRegisterCategoriesMap = {}
  },
  SET_SELECTED_WORK_TIME_REGISTER_CATEGORY_OPTIONS(state, payload) {
    state.worktimeRegisterCategoryOptions = payload
  },
  SET_DEPARTMENT_OPTIONS(state, payload) {
    state.departmentOptions = payload
  },
  SET_EMPLOYEE_GROUP_OPTIONS(state, payload) {
    state.employeeGroupOptions = payload
  },
  SET_POSITION_OPTIONS(state, payload) {
    state.positionOptions = payload
  },
  SET_APPLICATION_CATEGORIES(state, payload) {
    state.applicationCategories = payload  
  },
}

export const actions = {
  setSelectedEmployees({ commit }, payload) {
    commit('SET_SELECTED_EMPLOYEES', payload)
  },
  clearSelectedEmployees({ commit }) {
    commit('CLEAR_SELECTED_EMPLOYEES')
  },
  setWorktimeRegisterCategoriesMap({ commit }, payload) {
    commit('SET_SELECTED_WORK_TIME_REGISTER_CATEGORIES_MAP', payload)
  },
  clearWorktimeRegisterCategoriesMap({ commit }) {
    commit('CLEAR_SELECTED_WORK_TIME_REGISTER_CATEGORIES_MAP')
  },
  setWorktimeRegisterCategoryOptions({ commit }, payload) {
    commit('SET_SELECTED_WORK_TIME_REGISTER_CATEGORY_OPTIONS', payload)
  },
  setDepartmentOptions({ commit }, payload) {
    commit('SET_DEPARTMENT_OPTIONS', payload)
  },
  setEmployeeGroupOptions({ commit }, payload) {
    commit('SET_EMPLOYEE_GROUP_OPTIONS', payload)
  },
  setPositionOptions({ commit }, payload) {
    commit('SET_POSITION_OPTIONS', payload)
  },
  setApplicationCategories({ commit }, payload) {
    commit('SET_APPLICATION_CATEGORIES', payload)
  },
}
